import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember Me"
  const navigate = useNavigate();

  // Check if the user is already logged in on page load
  useEffect(() => {
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      navigate("/"); // Redirect to home if logged in
    }
  }, [navigate]);

  const parseErrorMessage = (errorMessage) => {
    const regex = /:\s*(.*)/;
    const match = errorMessage.match(regex);
    return match ? match[1].trim() : errorMessage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/login`;

      // Send POST request to API with login credentials and rememberMe flag
      const response = await axios.post(apiUrl, {
        email,
        password,
        rememberMe,
      });

      const { idToken, refreshToken, email: loggedInEmail } = response.data;

      // Store authentication details
      if (rememberMe) {
        localStorage.setItem("refreshToken", refreshToken); // Store refresh token in localStorage
      }
      localStorage.setItem("idToken", idToken); // Store in sessionStorage for session-only
      localStorage.setItem("email", loggedInEmail);

      setMessage("Login successful.");
      navigate("/"); // Redirect to home page
    } catch (error) {
      let errorMessage = "Login failed. Please try again.";

      if (error.response) {
        // Parse the error message if available
        const parsedMessage = error.response.data?.message
          ? parseErrorMessage(error.response.data.message)
          : "An unexpected error occurred. Please try again.";

        if (
          parsedMessage.includes("UserNotFoundException") ||
          parsedMessage.toLowerCase().includes("user does not exist")
        ) {
          errorMessage = "Incorrect email or password.";
        } else {
          errorMessage = parsedMessage;
        }
      } else if (error.request) {
        errorMessage =
          "Network error: Could not reach the server. Please check your connection.";
      } else {
        errorMessage = `Unexpected error: ${error.message}`;
      }

      setMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="remember-me">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)} // Toggle "Remember Me"
          />
          <label>Remember me</label>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Logging in..." : "Login"}
        </button>
      </form>

      {message && (
        <div className="error-message">
          <span>{message}</span>
        </div>
      )}

      <p>
        Don't have an account? <Link to="/create-account">Create one here</Link>
      </p>
    </div>
  );
};

export default LoginPage;
