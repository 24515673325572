import axios from "axios";

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (!refreshToken) {
    throw new Error("No refresh token found");
  }

  try {
    const apiUrl = `${process.env.REACT_APP_API_URL}/auth/refresh`;

    const response = await axios.post(apiUrl, { refreshToken });

    const { idToken } = response.data;

    // Store the new idToken in localStorage
    localStorage.setItem("idToken", idToken);

    return idToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    throw error;
  }
};
