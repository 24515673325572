import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { refreshToken } from "../utils/refreshToken"; // Import the refreshToken function

const HomePage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [downloadStatus, setDownloadStatus] = useState("");
  const [files, setFiles] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [emptyBucketMessage, setEmptyBucketMessage] = useState(""); // State for empty bucket message
  const apiUrl = `${process.env.REACT_APP_API_URL}`;
  const navigate = useNavigate(); // useNavigate hook inside the component

  // Check if user is logged in on page load
  useEffect(() => {
    const token = localStorage.getItem("idToken");
    if (token) {
      setIsLoggedIn(true);
    }
    fetchFiles(); // Fetch files on load
  }, []);

  // Handle logout
  const handleLogout = async () => {
    // Clear both tokens from localStorage, whether they exist or not
    localStorage.removeItem("idToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("email");

    // Update login status
    setIsLoggedIn(false);
    navigate("/login"); // Navigate to login page after logout
  };

  // Function to fetch files with token refresh logic
  const fetchFiles = async () => {
    try {
      setDownloadStatus("Loading files...");
      const token = localStorage.getItem("idToken");
      if (!token) {
        throw new Error("No token found");
      }

      const params = nextToken ? { nextToken } : {};
      const response = await axios.get(`${apiUrl}/pdf`, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        if (response.data.message) {
          setEmptyBucketMessage(response.data.message); // Set message if bucket is empty
          setFiles([]); // Clear files
        } else {
          setFiles((prevFiles) => [...prevFiles, ...response.data.files]);
          setNextToken(response.data.nextToken);
          setEmptyBucketMessage(""); // Clear message if files are retrieved
        }
        setDownloadStatus(""); // Clear the loading status
      } else {
        setDownloadStatus("Failed to fetch file list.");
      }
    } catch (error) {
      console.error("Error fetching files:", error);

      if (error.response && error.response.status === 401) {
        // If token expired, try refreshing the token
        try {
          const newToken = await refreshToken();
          fetchFiles(newToken); // Retry the API call with the new token
        } catch (refreshError) {
          setDownloadStatus("Session expired. Please log in again.");
          handleLogout(); // Log the user out if refresh fails
        }
      } else {
        setDownloadStatus("An error occurred while fetching files.");
      }
    }
  };

  // Function to handle file download with token refresh logic
  const handleFileDownload = async (fileName) => {
    try {
      setDownloadStatus("Downloading...");
      const token = localStorage.getItem("idToken");
      if (!token) {
        throw new Error("No token found");
      }

      const response = await axios.get(`${apiUrl}/pdf`, {
        params: { fileName },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const { body, isBase64Encoded } = response.data;

        if (isBase64Encoded) {
          const decodedFile = atob(body);
          const blob = new Blob(
            [
              new Uint8Array(
                [...decodedFile].map((char) => char.charCodeAt(0))
              ),
            ],
            { type: "application/pdf" }
          );
          const downloadUrl = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = fileName;
          link.click();

          setDownloadStatus("File downloaded successfully!");
        } else {
          setDownloadStatus(
            "Invalid file format. The file content is not Base64-encoded."
          );
        }
      }
    } catch (error) {
      console.error("Error during file download:", error);

      if (error.response && error.response.status === 401) {
        // If token expired, try refreshing the token
        try {
          const newToken = await refreshToken();
          handleFileDownload(fileName); // Retry the download with the new token
        } catch (refreshError) {
          setDownloadStatus("Session expired. Please log in again.");
          handleLogout(); // Log the user out if refresh fails
        }
      } else {
        setDownloadStatus(
          "An error occurred while downloading. Please try again."
        );
      }
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      setFile(uploadedFile);
      setUploadStatus("");
    } else {
      setUploadStatus("Please upload a valid PDF file.");
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64File = reader.result.split(",")[1]; // Get the Base64 part of the file
      const body = JSON.stringify({
        file: base64File,
        fileName: file.name,
      });

      try {
        setUploadStatus("Uploading...");
        const token = localStorage.getItem("idToken");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.post(`${apiUrl}/pdf`, body, {
          headers: {
            "Content-Type": "application/json", // Send JSON payload
            Authorization: `Bearer ${token}`, // Ensure user is authorized
          },
        });

        if (response.status === 200) {
          setUploadStatus("File uploaded successfully!");
        } else {
          setUploadStatus("Upload failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during file upload:", error);

        if (error.response && error.response.status === 401) {
          // If token expired, try refreshing the token
          try {
            const newToken = await refreshToken();
            handleFileUpload(); // Retry the upload with the new token
          } catch (refreshError) {
            setUploadStatus("Session expired. Please log in again.");
            handleLogout(); // Log the user out if refresh fails
          }
        } else {
          setUploadStatus(
            "An error occurred while uploading. Please try again."
          );
        }
      }
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="container">
      <h1>Welcome to Account Management</h1>

      {isLoggedIn ? (
        <div>
          <h2>Hello/Hallo/Hola!</h2>
          <button onClick={handleLogout}>Logout</button>

          <div className="upload-section">
            <h3>Upload a PDF file</h3>
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
            />
            <button onClick={handleFileUpload}>Upload PDF</button>
            {uploadStatus && <p>{uploadStatus}</p>}
          </div>

          <div className="file-list">
            <h3>File List</h3>
            {emptyBucketMessage ? (
              <p>{emptyBucketMessage}</p> // Display message if bucket is empty
            ) : (
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    <span>
                      {file.fileName} - {file.lastModified}
                    </span>
                    <button onClick={() => handleFileDownload(file.fileName)}>
                      Download
                    </button>
                  </li>
                ))}
              </ul>
            )}
            {nextToken && <button onClick={fetchFiles}>Load More</button>}
          </div>

          {downloadStatus && <p>{downloadStatus}</p>}
        </div>
      ) : (
        <p>
          Please <Link to="/login">Login</Link> or{" "}
          <Link to="/create-account">Create an Account</Link>.
        </p>
      )}
    </div>
  );
};

export default HomePage;
