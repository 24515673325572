import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CreateAccountPage = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const parseErrorMessage = (errorMessage) => {
    const regex = /:\s*(.*)/;
    const match = errorMessage.match(regex);
    if (match && match[1]) {
      return match[1].trim();
    }
    return errorMessage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setIsSuccess(false);

    if (!password || !email) {
      setMessage("Please fill in all fields.");
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    setIsLoading(true);

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/auth/signup`;
      const response = await axios.post(apiUrl, { password, email });

      setMessage(response.data.message || "Account created successfully.");
      setIsSuccess(true);
    } catch (error) {
      let errorMessage = "Error creating account. Please try again.";
      setIsSuccess(false);

      if (error.response) {
        const parsedMessage = error.response.data?.message
          ? parseErrorMessage(error.response.data.message)
          : "An unexpected error occurred. Please try again.";

        if (error.response.status === 400) {
          errorMessage =
            parsedMessage || "Bad request. Please check your input.";
        } else if (error.response.status === 409) {
          errorMessage =
            parsedMessage ||
            "Email already exists. Please choose a different one.";
        } else if (
          error.response.data?.errorCode === "InvalidPasswordException"
        ) {
          errorMessage = "Password must have uppercase characters.";
        } else if (error.response.status === 500) {
          errorMessage = "Server error. Please try again later.";
        } else {
          errorMessage = parsedMessage;
        }
      } else if (error.request) {
        errorMessage =
          "Network error: Could not reach the server. Please check your connection.";
      } else {
        errorMessage = `Unexpected error: ${error.message}`;
      }

      setMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-account-page">
      <h2>Create Account</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Creating Account..." : "Create Account"}
        </button>
      </form>
      {message && (
        <p className={isSuccess ? "success-message" : "error-message"}>
          {message}
        </p>
      )}
      <p>
        Already have an account? <Link to="/login">Login here</Link>
      </p>
    </div>
  );
};

export default CreateAccountPage;
